import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ["output", "input", "customer"]

    set() {
        const option = this.customerTarget.querySelector("option:checked")

        if (option.value =='') return;

        const days = parseInt(option.dataset.termDelay)
        const type = option.dataset.termType

        const obligationDate = this.parse(this.inputTarget.value)

        if (obligationDate) {
            let dueDate = new Date(obligationDate)
            dueDate.setDate(obligationDate.getDate() + days)

            // TODO Handle the case where term is end-of-month
            this.outputTarget.value = this.format(dueDate)
        }
    }

    format(date) {
        const f = (v) => ("0" + v).slice(-2);

        const day = f(date.getDate());
        const month = f(date.getMonth() + 1);
        const year = date.getFullYear();

        return [year,month,day].join("-")
    }

    parse(dateString) {
        const parts = dateString.split('-');

        if (parts.length < 3) return null;

        const day = parseInt(parts[2], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[0], 10);
        return new Date(year, month, day);
    }
}