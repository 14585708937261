import Turbolinks from 'turbolinks'
import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

//const images = require.context('../images', true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'controllers'

import 'theme/toolkit-light'

import 'stylesheets/obligations'
import 'stylesheets/algolia'

import 'bootstrap/dist/js/bootstrap.min.js'
import "@fortawesome/fontawesome-free/css/all"