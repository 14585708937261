import { Controller } from 'stimulus'
import Pikaday from 'pikaday'

require('pikaday/css/pikaday.css');

export default class extends Controller {
    connect() {
        const f = (v) => ("0" + v).slice(-2);

        this.picker = new Pikaday({
            field: this.element, 
            format: 'YYYY-MM-DD',
            toString(date, format) {

                const day = f(date.getDate());
                const month = f(date.getMonth() + 1);
                const year = date.getFullYear();

                return [year,month,day].join("-")
            },
            parse(dateString, format) {
                // dateString is the result of `toString` method
                const parts = dateString.split('/');

                const day = parseInt(parts[2], 10);
                const month = parseInt(parts[1], 10) - 1;
                const year = parseInt(parts[0], 10);
                return new Date(year, month, day);
            },
            onSelect: () => {
                this.element.dispatchEvent(new CustomEvent("select"))
            }
        })
    }
    disconnect() {
        this.picker.destroy()
    }
}