import { Controller } from 'stimulus'

export default class extends Controller {
    static values = { hide: String }

    static targets = ['item']
    static classes = ['hidden']

    connect() {
        this.className = this.hasHiddenClass ? this.hiddenClass : 'hidden'
    }

    toggle(event) {
        if (event.target.value == this.hideValue) {
            this.hide()
        } else {
            this.show()
        }
    }

    show() {
        const className = this.className

        this.itemTargets.forEach(item => {
            item.classList.remove(className)
        })
    }

    hide() {
        const className = this.className

        this.itemTargets.forEach(item => {
            item.classList.add(className)
        })
    }
}