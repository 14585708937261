import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["input"]

    fetch(event) {
        event.preventDefault()

        const v = this.inputTarget.value

        if (v == '') return

        window.location = v
    }
}