import { Controller } from "stimulus"
import autocomplete from "autocomplete.js"

export default class extends Controller {
  static values = {
    customerUrl: String,
    deviceUrl: String,
    obligationUrl: String
  }

  static targets = ["input"]

  connect() {
    const options = {
      debug: true,
      minLength: 3
    }

    autocomplete(this.inputTarget, options, [this.deviceDataSet, this.customerDataSet, this.obligationDataSet]);
  }

  get customerDataSet() {
    const url = new URL(this.customerUrlValue)

    return {
      source: function (query, cb) {
        url.searchParams.set("query", query)

        fetch(url).then((response)=>response.json()).then((data) => cb(data.options))
      },
      name: "customers",
      templates: {
        header: "<h4>Customers</h4>",
        suggestion: (suggestion) => `<a href='${suggestion.href}'>${suggestion.name}</a>`
      }
    }
  }

  get deviceDataSet() {
    const url = new URL(this.deviceUrlValue)

    return {
      source: function (query, cb) {
        url.searchParams.set("query", query)
        
        fetch(url).then((response)=>response.json()).then((data) => cb(data.options))
      },
      name: "devices",
      templates: {
        header: "<h4>Devices</h4>",
        suggestion: (suggestion) => `<a href='${suggestion.href}'>${suggestion.iccid}</a>`
      }
    }
  }

  get obligationDataSet() {
    const url = new URL(this.obligationUrlValue)

    return {
      source: function (query, cb) {
        url.searchParams.set("query", query)
        
        fetch(url).then((response)=>response.json()).then((data) => cb(data.options))
      },
      name: "obligations",
      templates: {
        header: "<h4>Obligations</h4>",
        suggestion: (suggestion) => `<a href='${suggestion.href}'>${suggestion.number}</a>`
      }
    }
  }
}
