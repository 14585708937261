import { Controller } from "stimulus"
import autocomplete from "autocomplete.js"

export default class extends Controller {
  static values = {
    url: String,
  }

  static targets = ["input"]

  connect() {
    const options = {
      debug: true,
      minLength: 3
    }

    autocomplete(this.inputTarget, options, [this.customerDataSet]);
  }

  get customerDataSet() {
    const url = new URL(this.urlValue)

    return {
      source: function (query, cb) {
        url.searchParams.set("query", query)

        fetch(url).then((response)=>response.json()).then((data) => cb(data.options))
      },
      name: "customers",
      templates: {
        suggestion: (suggestion) => `<a href='${suggestion.href}'>${suggestion.name}</a>`
      }
    }
  }
}
